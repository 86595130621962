import { Box, Typography } from "@material-ui/core"
import * as React from "react"
import Layout from "../components/Layout"

type Props = {}

const FourOFour: React.FC<Props> = () => {
  return (
    <Layout>
      <Box
        display={"flex"}
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        <Typography variant="h4">404</Typography>
        <Typography variant="h5">sorry page not found.</Typography>
      </Box>
    </Layout>
  )
}

export default FourOFour
